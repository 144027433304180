export const Label = {
  "en": "English",
  "el": "Greek",
  "sq": "Albanian",
  "ar": "Arabic",
  "bg": "Bulgarian",
  "zh": "Chinese",
  "da": "Danish",
  "nl": "Dutch",
  "fi": "Finnish",
  "fr": "French",
  "de": "German",
  "he": "Hebrew",
  "hi": "Hindi",
  "hu": "Hungarian",
  "it": "Italian",
  "ja": "Japanese",
  "ko": "Korean",
  "no": "Norwegian",
  "pt": "Portuguese",
  "ro": "Romanian",
  "ru": "Russian",
  "sr": "Serbian",
  "es": "Spanish",
  "sw": "Swahili",
  "sv": "Swedish",
  "th": "Thai"
}