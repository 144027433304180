import i18n from "i18next";
import TextToSpeechButton from "../components/tts/TextToSpeechButton";

export const animateCSS = (node, animationName, callback) => {
  node.classList.add('animated', 'slower', animationName);

  function handleAnimationEnd() {
    node.classList.remove('animated', 'slower', animationName);
    node.removeEventListener('animationend', handleAnimationEnd);

    if (typeof callback === 'function') callback()
  }

  node.addEventListener('animationend', handleAnimationEnd);
}

export const pushHistoryState = (view, action, state) => {
  let currentView = { currView: view }

  if (typeof state === "object")
    currentView = { ...currentView, ...state }


  // if history state it's empty, initialized with current view object
  if (window.history.state === null || action === "forceReplace") {
    window.history.replaceState(currentView, "", "")
    return
  }

  // if the current view is the last view on history state, the push gets ignored 
  if (window.history.state.currView === view)
    return

  window.history.pushState(currentView, "", "")
}

export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export const imagePlaceHolder = process.env.PUBLIC_URL + '/assets/images/no-image-new.png'
export const setImagePlaceholder = (e) => {
  e.target.src = imagePlaceHolder
}

export const localUserPic = process.env.PUBLIC_URL + '/assets/images/ars/local-user.png'
export const remoteUserPic = process.env.PUBLIC_URL + '/assets/images/ars/remote-user.png'

export const capitaliseName = (str) => {
  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(" ");

  return str2
}

export const fnBrowserDetect = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "none";
  }

  return browserName
}

export const validURL = (str) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

// Convert file to base64 string
export const fileToBase64 = (file) => {
  return new Promise(resolve => {
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    // Convert data to base64 
    reader.readAsDataURL(file);
  });
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function toDateString(date) {
  return ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
}

export const notificationSound = process.env.PUBLIC_URL + '/assets/sounds/notification-sound.mp3'
export const vlLogo = process.env.PUBLIC_URL + '/assets/images/vlenz-black-hori.png'
export const vlFavicon = process.env.PUBLIC_URL + '/assets/images/favicon.ico'
export const evenlyLogo = process.env.PUBLIC_URL + '/assets/images/evenly-std-dark.min.svg'
export const evenlyFavicon = process.env.PUBLIC_URL + '/assets/images/evenly-fav.svg'
export const liveChatIcon = process.env.PUBLIC_URL + '/assets/images/livechat.svg'

export function changeMetaTags(t, isEvenly) {
  const ns = isEvenly ? nsEvenly : nsVL;
  const authorTranslation = t('meta.author', ns);
  const descrTranslation = t('meta.description', ns);
  document.querySelector('meta[name="author"]').setAttribute("content", authorTranslation);
  document.querySelector('meta[name="description"]').setAttribute("content", descrTranslation);
}

export function changeFavicon(src, isEvenly) {
  const defaultFavicon = isEvenly ? evenlyFavicon : vlFavicon;
  src = src || defaultFavicon;
  document.getElementById("favicon").href = src;
  document.querySelector("link[rel~='icon']").href = src;
}

export function compareTimestamp(a, b) {
  return a.timestamp - b.timestamp;
}

export function compareKey(a, b) {
  return a.key - b.key;
}

export const ttsExcludedLangs = ["sw", "fa"];

export function captionsModal(captions, productless, broadcast = false, demo = false, tts = false) {
  scrollCaptions();
  const productlessClass = productless ? " productless" : "";
  const broadcastClass = !productless && broadcast ? " broadcast fixed-bottom-subtitles-host" : "";
  const broadcastOnlyClass = !productless && broadcast ? " broadcast" : "";
  let localParticipantResult;
  if (captions[0].result)
    if (!captions[0].sender)
      localParticipantResult = <p dir={i18n.dir(captions[0]?.lang)}>{cleanCaptions(captions[0].result)}</p>;
    else
      localParticipantResult =
        <p dir={i18n.dir(captions[0].lang)}>
          <span>{captions[0].sender}: </span>
          <span>{cleanCaptions(captions[0].result)}</span>
        </p>;

  let body =
    <div id="cc-entry" className={`cc-entry${broadcastOnlyClass}`}>
      {localParticipantResult && localParticipantResult}
      {!!captions[1]?.result && <p className={`speaker-${captions[1].sender.split(":")[1]}`} dir={i18n.dir(captions[1]?.lang)}>{captions[1].sender.split(":")[0]}: {cleanCaptions(captions[1].result)}</p>}
      {!!captions[2]?.result && <p className={`speaker-${captions[2].sender.split(":")[1]}`} dir={i18n.dir(captions[2]?.lang)}>{captions[2].sender.split(":")[0]}: {cleanCaptions(captions[2].result)}</p>}
    </div>;

  if (demo) {
    const ttsButton = (lang, text) => !ttsExcludedLangs.includes(lang) && <TextToSpeechButton lang={lang} text={text} />
    body =
      <>
        <div id="cc-entry" className={`cc-entry${broadcastOnlyClass}`}>
          {localParticipantResult && localParticipantResult}
        </div>
        <div id="cc-entry" className={`cc-entry${broadcastOnlyClass} mt-5`}>
          {!!captions[1]?.result && <p className={`speaker-${captions[1].sender.split(":")[1]}`} dir={i18n.dir(captions[1]?.lang)}>{captions[1].sender.split(":")[0]}: {cleanCaptions(captions[1].result)} {tts && ttsButton(captions[1].lang, cleanCaptions(captions[1].result))} </p>}
        </div>
        <div id="cc-entry" className={`cc-entry${broadcastOnlyClass} mt-5`}>
          {!!captions[2]?.result && <p className={`speaker-${captions[2].sender.split(":")[1]}`} dir={i18n.dir(captions[2]?.lang)}>{captions[2].sender.split(":")[0]}: {cleanCaptions(captions[2].result)} {tts && ttsButton(captions[2].lang, cleanCaptions(captions[2].result))}</p>}
        </div>
      </>;
  }

  return (
    <div className={`modal cc-modal${productlessClass}`} tabIndex="-1" role="dialog" style={{ display: "block", zIndex: "999" }}>
      <div className={`cc-modal-dialog${productlessClass}${broadcastClass}`} role="document">
        <div className="cc-modal-content cc-modal-content-dark">
          <div className={`cc-modal-body${broadcastOnlyClass}`}>
            {body}
          </div>
        </div>
      </div>
    </div>
  )
}

export function captionsResult(messages) {
  let result;
  if (messages?.length > 0) {
    const messagesLast = messages[messages.length - 1];
    const secondsDiff = Math.floor((new Date().getTime() - new Date(messagesLast.timestamp).getTime()) / 1000);
    if (secondsDiff < 5) {
      const { data } = JSON.parse(messagesLast.message);
      if (typeof data.result != "string") return "";
      // Split to words
      let wordsArr = data.result.split(" ");
      if (wordsArr.length > 30)
        result = wordsArr.slice(20, wordsArr.length).join(" ");
      else
        result = data.result;
    }
  }

  return result;
}

function scrollCaptions() {
  let elem = document.getElementById("cc-entry");
  if (elem)
    elem.scrollTop = elem.scrollHeight;
}

function cleanCaptions(text) {
  const words = ["f ", "f,", "sf "];
  const contains = words.some((word) => text.toLowerCase().startsWith(word));
  if (contains)
    return text.substr(2);

  return text;
}

export const END_CALL_WITH_BASKET = "END_CALL_WITH_BASKET";
export const END_CALL_WITHOUT_BASKET = "END_CALL_WITHOUT_BASKET";

export const VL_ASSETS_URL = "https://drake-space-01.fra1.cdn.digitaloceanspaces.com/vl_assets";
export const dummyParticipantVideos = [VL_ASSETS_URL + "/demos/dummyparticipant1.mp4", VL_ASSETS_URL + "/demos/dummyparticipant2.mp4"];

export function formatDuration(durationInMilliseconds) {
  const durationInSeconds = Math.floor(durationInMilliseconds / 1000);
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  return (
    String(hours).padStart(2, "0") +
    ":" +
    String(minutes).padStart(2, "0") +
    ":" +
    String(seconds).padStart(2, "0")
  );
}

export const bgTileChatUI = VL_ASSETS_URL + "/images/ars/bg-tile-chat-ui.png";

export function isWorkingHour(workingHours, currentDate = new Date()) {
  const day = currentDate.toLocaleDateString("en-US", { weekday: "short" }).toLowerCase();
  const time = currentDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });

  if (!workingHours)
    return undefined;

  if (!workingHours[day])
    return false;

  return workingHours[day].every(d => {
    return d.start_tme < time && d.end_tme > time;
  });
}

export const nsCommon = { ns: 'common' };
export const nsEvenly = { ns: 'evenly' };
export const nsVL = { ns: 'vl' };

export function loadCustomStyle(filename) {
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = `${VL_ASSETS_URL}/css/${filename}.css`;
  // Read local CSS file in development mode
  if (process.env.NODE_ENV === 'development')
    link.href = `${process.env.PUBLIC_URL}/assets/css/${filename}.css`;
  document.getElementsByTagName("HEAD")[0].appendChild(link);
}

const imageTypes = [".jpg", ".png", ".jpeg", ".svg"];
const docTypes = [".doc", ".docx", ".pdf"];

export function detectFileTypeFromURL(url) {
  let ext = "." + url.split(/[#?]/)[0].split('.').pop().trim();
  const imageTypes = [".jpg", ".png", ".jpeg", ".svg"];
  if (imageTypes.includes(ext))
    return "image";
  if (docTypes.includes(ext))
    return "document";

  return "";
}
export const filesToAccept = imageTypes.concat(docTypes);

export function randomAlphaNumeric(length) {
  let s = '';
  Array.from({ length }).some(() => {
    s += Math.random().toString(36).slice(2);
    return s.length >= length;
  });
  return s.slice(0, length);
}

export function removeURLParameter(url, parameter) {
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);
    for (var i = pars.length; i-- > 0;)
      if (pars[i].lastIndexOf(prefix, 0) !== -1)
        pars.splice(i, 1);
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export const statusColorMap = {
  "assigned": "blue",
  "cancelled": "red",
  "completed": "green",
  "ongoing": "orange",
  "expired": "grey",
}

export const replaceOrAppend = (oldArr, newArr, compFn) => {
  const res = [...oldArr];
  newArr.forEach(val => {
    const i = oldArr.findIndex(v => compFn(v, val));
    if (i === -1) res.push(val);
    else res.splice(i, 1, val);
  });
  return res;
};

export const createVideoSDKRoom = async (vtoken, name = "demo") => {
  let url = "https://api.videosdk.live/v2/rooms";
  try {
    const response = await fetch(
      url, {
      method: "POST",
      headers: {
        "Authorization": vtoken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        customRoomId: name + "-" + randomAlphaNumeric(6)
      })
    });
    const jsonData = await response.json();
    return jsonData.roomId;
  } catch (error) {
    console.error('Error fetching session:', error);
  }
}
